<template>
    <div class="">
        <v-row class="intro">
            <v-col cols="12" lg="6" class="mb-10">
                <h1 class="heading-2 mb-4 mb-md-10">Join Our Team <br/>and Become an Agent</h1>
                <p class="font-16 mb-10 w-70">If you are a skillful salesperson Freight Crew is the partner to help you grow and increase your income.</p>
                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed @click.stop="$refs.agentform.changeDialog()">Become an agent <v-icon>mdi-chevron-right</v-icon></v-btn>
                <agent-form ref="agentform"/>
            </v-col>
            <v-col cols="12" lg="6" class="d-none d-sm-block">
                <v-row>
                    <v-col cols="12" sm="6">
                        <star-card color="bg-transparent">
                            <template v-slot:title>
                                Freedom <br/> and creativity
                            </template>
                            <template v-slot:description>
                                We create the working atmosphere and culture that stimulate freedom and creativity to produce the most efficient solutions to time-critical needs
                            </template>
                        </star-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <star-card color="bg-transparent">
                            <template v-slot:title>
                                Development assistance
                            </template>
                            <template v-slot:description>
                                We are committed to modern technology and advanced software that we regularly update. We choose the products and solutions that enhance connectivity, safety, and efficiency to satisfy all the needs of our partners and clients
                            </template>
                        </star-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <star-card color="bg-transparent">
                            <template v-slot:title>
                                Bonuses and unlimited income
                            </template>
                            <template v-slot:description>
                                Our agents receive the commissions of 50% of the profit on all shipments. You get the possibility to generate unlimited income. So, your income depends only on your effort and dedication
                            </template>
                        </star-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <star-card color="bg-transparent">
                            <template v-slot:title>
                                Support
                            </template>
                            <template v-slot:description>
                                Our staff is the most valuable asset we have, as our team are professionals with expansive experience, constantly developing and improving their skills. We provide corporate support and training regularly
                            </template>
                        </star-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-sheet
            class="mx-auto bg-transparent d-block d-sm-none"
            max-width="600"
        >
            <v-slide-group
                class="pa-md-4"
            >
                <v-slide-item
                    v-slot="{ active, toggle }"
                >
                    <star-card color="bg-transparent">
                        <template v-slot:title>
                            Freedom <br/> and creativity
                        </template>
                        <template v-slot:description>
                            We create the working atmosphere and culture that stimulate freedom and creativity to produce the most efficient solutions to time-critical needs
                        </template>
                    </star-card>
                </v-slide-item>
                <v-slide-item
                    v-slot="{ active, toggle }"
                >
                    <star-card color="bg-transparent">
                        <template v-slot:title>
                            Development assistance
                        </template>
                        <template v-slot:description>
                            We are committed to modern technology and advanced software that we regularly update. We choose the products and solutions that enhance connectivity, safety, and efficiency to satisfy all the needs of our partners and clients
                        </template>
                    </star-card>
                </v-slide-item>
                <v-slide-item
                    v-slot="{ active, toggle }"
                >
                    <star-card color="bg-transparent">
                        <template v-slot:title>
                            Bonuses and unlimited income
                        </template>
                        <template v-slot:description>
                            Our agents receive the commissions of 50% of the profit on all shipments. You get the possibility to generate unlimited income. So, your income depends only on your effort and dedication
                        </template>
                    </star-card>
                </v-slide-item>
                <v-slide-item
                    v-slot="{ active, toggle }"
                >
                    <star-card color="bg-transparent">
                        <template v-slot:title>
                            Support
                        </template>
                        <template v-slot:description>
                            Our staff is the most valuable asset we have, as our team are professionals with expansive experience, constantly developing and improving their skills. We provide corporate support and training regularly
                        </template>
                    </star-card>
                </v-slide-item>
            </v-slide-group>
        </v-sheet>
    </div>
</template>

<script>
import StarCard from '@/components/base/StarCard.vue'
import AgentForm from '@/components/pages/AgentForm.vue'

export default {
    name: 'JoinOurTeam',
    components: {
        StarCard,
        AgentForm
    },
    data: () => ({
        agent_dialog: false,
    })
}
</script>
<style lang="scss">
    .v-slide-group__content .starcard{
        width: 240px;
        margin-right: 10px;
    }
    .v-slide-group__content .starcard.h-100{
        height: auto;
    }
</style>
