export default {
    data: () => ({
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        requiredRules: [
            v => !!v || 'Item is required'
        ],
        phoneRules: [
            v => !!v || 'Phone is required',
            v => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Phone must be valid',
        ],
    })
}
