<template>
    <div class="aircraftcity py-4 py-sm-16">
        <v-container class="my-6">
            <h1 class="heading-2 mb-md-10 white--text">We Deliver Worldwide</h1>
            <v-row>
                <v-col cols="12" lg="7">
                    <p class="text-16 white--text">Our main office is located in the USA, but our agents and partners also work in Canada and Mexico. Thus, we can provide freight shipping not only between capitals, but between any cities in Canada, the USA, and Mexico.</p>
                </v-col>
            </v-row>
            <img src="@/assets/icons/svg/airplane-blue-top.svg" class="d-block d-sm-none miniairplane" alt="">
        </v-container>
        <div class="p-relative d-none d-sm-block">
            <div class="passed_city" :class="{ 'visible animated': showAnimation, 'invisible': !showAnimation }"></div>
            <div class="full-horizontal-line-aircraft" v-observe-visibility="{ callback: isViewableNow, once: true }" :class="{ 'visible animated': showAnimation, 'invisible': !showAnimation }">
                <img src="@/assets/icons/svg/aircraft2.svg" alt="">
            </div>
            <v-container class="py-6">
                <v-list color="transparent" flat>
                    <v-list-item-group class="d-flex justify-space-between">
                        <v-list-item class="d-block" :ripple="false">
                            <v-list-item-icon class="mb-10">
                                <img src="@/assets/icons/svg/white-point.svg" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-20-60 white--text">
                                    San-Francisco
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-block" :ripple="false">
                            <v-list-item-icon class="mb-10">
                                <img src="@/assets/icons/svg/white-point.svg" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-20-60 white--text">
                                    New York
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-block" :ripple="false">
                            <v-list-item-icon class="mb-10">
                                <img src="@/assets/icons/svg/white-point.svg" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-20-60 white--text">
                                    Ontario
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-block" :ripple="false">
                            <v-list-item-icon class="mb-10">
                                <img src="@/assets/icons/svg/white-point.svg" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-20-60 white--text">
                                    Toronto
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-block" :ripple="false">
                            <v-list-item-icon class="mb-10">
                                <img src="@/assets/icons/svg/white-point.svg" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-20-60 white--text">
                                    Guadalajara
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-none d-md-block" :ripple="false">
                            <v-list-item-icon class="mb-10">
                                <img src="@/assets/icons/svg/white-point.svg" alt="">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-20-60 white--text">
                                    Mexico City
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-container>
        </div>
    </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';
export default {
    name: 'AircraftCity',
    directives: {
        ObserveVisibility
    },
    data: () => ({
        showAnimation: false,
    }),
    methods: {
        isViewableNow(isVisible, entry) {
            this.showAnimation = isVisible;
        }
    }
}
</script>

<style lang="scss">
.aircraftcity{
    background: rgba(84, 150, 240, 1);
}
.full-horizontal-line-aircraft{
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    img{
        z-index: 1;
        position: relative;
    }
}
.full-horizontal-line-aircraft::before{
    border-bottom: 1px dashed #91B9FB;
    content: " ";
    width: 100%;
    position: absolute;
    opacity: 1;
    right: auto;
    left: 0;
    top: 62px;
}
.passed_city{
    width: 0%;
    height: 100%;
    right: 0;
    position: absolute;
    z-index: 10;
    // background: linear-gradient(266.94deg, rgba(45, 101, 210, 0.5) 5.32%, rgba(78, 145, 255, 0.5) 78.35%) !important;
    background: rgba(84, 150, 240, 0.5);
}
.passed_city.animated{
    -webkit-animation: horizontal-passed_city-animation 4.5s linear;
            animation: horizontal-passed_city-animation 4.5s linear;
}
.miniairplane{
    position: absolute;
    right: 0;
    margin-top: -50px;
}
// .passed_city.animated{
//     -webkit-animation: horizontal-aircraft-passed-animation 4.5s linear;
//             animation: horizontal-aircraft-passed-animation 4.5s linear;
// }
.full-horizontal-line-aircraft.animated{
    img{
        // position: absolute;
        // right: 0;
        z-index: 11;
        margin-left: 90%;
    	-webkit-animation: horizontal-line-aircraft-animation 4.5s linear;
    	        animation: horizontal-line-aircraft-animation 4.5s linear;
    }

}
@-webkit-keyframes horizontal-line-aircraft-animation {
    0% {
        -webkit-transform: translateX(-159px);
              transform: translateX(-159px);
    }
    100% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}
@keyframes horizontal-line-aircraft-animation {
    0% {
        // -webkit-transform: translateX(-159px);
        //         transform: translateX(-159px);
        margin-left: -159px;
        // left: -159px;
    }
    100% {
        // -webkit-transform: translateX(100%);
        //         transform: translateX(100%);
        margin-left: 90%;
        // right: 0;

    }
}

@keyframes horizontal-passed_city-animation {
    0% {
        // -webkit-transform: translateX(-159px);
        //         transform: translateX(-159px);
        width: 100%;

        // left: -159px;
    }
    100% {
        // -webkit-transform: translateX(100%);
        //         transform: translateX(100%);
        width: 0%;

        // right: 0;

    }
}

</style>
