export default {
    data: () => ({
        form: {
            from: {
                zip: '',
                id_city: '',
                city: '',
                state: '',
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: new Date().toISOString().substr(0, 10),
                datepicker: false,
                geoModel: '',
                geoItems: [],
                geoSearch: null,
                geoSearch1: null,
                geoSelected: false,
            },
            to: {
                zip: '',
                id_city: '',
                city: '',
                state: '',
                geoModel: '',
                geoItems: [],
                geoSearch: null,
                geoSearch1: null,
                geoSelected: false,
            }
        },

    }),
    watch: {
        'form.from.geoSearch'(val) {
            if(val){
                if(this.form.from.geoSelected){
                    this.form.from.geoSelected = false
                    this.form.from.getItems = []
                }
                val && val !== this.select && val.length > 2 && val.length < 15 && this.querySelections(val, 'from')
                if(val.length <= 2)
                this.form.from.geoItems = [];
            }
        },
        'form.from.geoModel': function(val, oldVal){
            if(val){
                this.geoSelected = true
                this.form.from.id_city = val.id;
                this.form.from.zip = val.address.postalCode;
                this.form.from.city = val.address.city;
                this.form.from.state = val.address.stateCode;
            }
        },
        'form.to.geoSearch'(val) {
            if(val){
                if(this.form.to.geoSelected){
                    this.form.to.geoSelected = false
                    this.form.to.getItems = []
                }
                val && val !== this.select && val.length > 2 && val.length < 15 && this.querySelections(val, 'to')
                if(val.length <= 2)
                    this.form.to.geoItems = [];
            }
        },
        'form.to.geoModel': function(val, oldVal){
            if(val){
                this.form.to.geoSelected = true
                this.form.to.id_city = val.id;
                this.form.to.zip = val.address.postalCode;
                this.form.to.city = val.address.city;
                this.form.to.state = val.address.stateCode;
            }
        },
        'form.from.geoSearch1'(val) {
            if(val){
                if(this.form.from.geoSelected){
                    this.form.from.geoSelected = false
                    this.form.from.getItems = []
                }
                val && val !== this.select && val.length > 2 && val.length < 15 && this.querySelections(val, 'from')
                if(val.length <= 2)
                this.form.from.geoItems = [];
            }
        },
        'form.to.geoSearch1'(val) {
            if(val){
                if(this.form.to.geoSelected){
                    this.form.to.geoSelected = false
                    this.form.to.getItems = []
                }
                val && val !== this.select && val.length > 2 && val.length < 15 && this.querySelections(val, 'to')
                if(val.length <= 2)
                    this.form.to.geoItems = [];
            }
        },


    },
    methods: {
        querySelections(v, type) {
            this.axios.get(process.env.VUE_APP_API + 'countries/v1/addressfind/' + v).then(response => {
                this.form[type].geoItems = response.data.items.filter(item => {

                    if(['street', 'locality'].indexOf(item.resultType) < 0)
                        return false;


                    if(!('city' in item.address))
                        return false;

                    item.label = item.address.stateCode + ', ' + item.address.city + ', ' + item.address.postalCode
                    return item
                })
            }, error => {
                console.log(error);
            }).catch(error => {
                console.log(error);
            })
        },
    },
}
// 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=LmxNTn2RZKi8r0OQgBBBtE1j9Y4ga5nPDXUB8oaf6Jc&query=' + v
