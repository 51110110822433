<template>
    <div class="">
        <v-app-bar
            clipped-left
            flat
            class="transparent px-0 mainmenu index p-relative"
        >

            <div class="d-flex justify-space-between align-center align-self-center w-100">
                <v-list class="transparent" flat>
                    <v-list-item-group class="d-flex flex-row align-self-center">
                        <v-list-item class="px-0" :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title class="d-flex">
                                    <div class="burger d-none d-lg-block mr-8">
                                        <img src="@/assets/icons/svg/burger.svg" @click.stop="drawer = !drawer" alt="">
                                    </div>
                                    <router-link :to="{name: 'frontend.home'}">
                                        <img v-if="(!xswhite && $vuetify.breakpoint.xs) || $vuetify.breakpoint.smAndUp" src="@/assets/logo/newwhite.svg" alt="Logo" width="147">
                                        <img v-if="xswhite && $vuetify.breakpoint.xs" src="@/assets/logo/newblack.svg" alt="Logo" width="147">
                                    </router-link>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item disabled class="d-none d-md-block">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div class="delivery">
                                        <div class="text text-uppercase white--text body-3">
                                            Deliver To:
                                        </div>
                                        <div class="icons d-flex mt-1">
                                            <img src="@/assets/icons/flag/usa.svg" class="mr-1" alt="USA" width="25">
                                            <img src="@/assets/icons/flag/canada.svg" class="mx-1" alt="USA" width="25">
                                            <img src="@/assets/icons/flag/mexico.svg" class="mx-1" alt="USA" width="25">
                                        </div>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-list v-if="!tracksearch" class="transparent mx-auto d-none d-lg-block" flat>
                    <v-list-item-group class="d-flex flex-row align-self-center h-100">
                        <v-list-item :ripple="false">
                            <v-list-item-content>
                                <router-link :to="{ name: 'frontend.calculator', params: {} }">
                                    <v-list-item-title v-text="`Get quotes`" class="text-16-60 white--text"></v-list-item-title>
                                </router-link>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :ripple="false">
                            <v-menu offset-y open-on-hover close-delay="500" content-class="frontendmenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-text="`Services`" class="text-16-60 white--text" v-bind="attrs" v-on="on"></v-list-item-title>
                                </template>
                                <v-list>
                                    <v-list-item class="justify-center" :ripple="false">
                                        <router-link :to="{name: 'frontend.services.expedited'}" class="text-decoration-none py-3"><v-list-item-title class="text-14">Expedited shipping <v-icon>mdi-chevron-right</v-icon></v-list-item-title></router-link>
                                    </v-list-item>
                                    <v-list-item class="justify-center" :ripple="false">
                                        <router-link :to="{name: 'frontend.services.ltl'}" class="text-decoration-none py-3"><v-list-item-title class="text-14">LTL Shipping <v-icon>mdi-chevron-right</v-icon></v-list-item-title></router-link>
                                    </v-list-item>
                                    <v-list-item class="justify-center" :ripple="false">
                                        <router-link :to="{name: 'frontend.services.international'}" class="text-decoration-none py-3"><v-list-item-title class="text-14">International shipping <v-icon>mdi-chevron-right</v-icon></v-list-item-title></router-link>
                                    </v-list-item>
                                    <v-list-item class="justify-center" :ripple="false">
                                        <router-link :to="{name: 'frontend.services.airfreight'}" class="text-decoration-none py-3"><v-list-item-title class="text-14">Airfreight <v-icon>mdi-chevron-right</v-icon></v-list-item-title></router-link>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                        <v-list-item :ripple="false">
                            <v-list-item-content>
                                <router-link :to="{name: 'frontend.agent'}"><v-list-item-title v-text="`Agents`" class="text-16-60 white--text"></v-list-item-title></router-link>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :ripple="false">
                            <v-list-item-content>
                                <router-link :to="{name: 'frontend.contact'}"><v-list-item-title v-text="`Contact us`" class="text-16-60 white--text"></v-list-item-title></router-link>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-list v-if="!tracksearch" class="transparent d-none d-lg-flex" flat>
                    <v-list-item-group class="d-flex flex-row align-self-center">
                        <v-list-item :ripple="false">
                            <v-list-item-content class="py-0">
                                <v-list-item-title v-text="`ES`" class="text-16-60 white--text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-block" :ripple="false" @click="tracksearch = true">
                            <v-list-item-icon class="d-block ma-0 text-center menurightlistheight">

                                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.2646 20.7315C11.6985 20.7315 12.2918 19.0578 12.2918 16.2682V8.40423C14.1516 7.94374 15.5065 6.26113 15.5065 4.26858C15.5065 1.91293 13.6202 0 11.2646 0C8.90009 0 7.0138 1.91293 7.0138 4.26858C7.0138 6.25228 8.36874 7.93487 10.2196 8.40423V16.2682C10.2196 19.0489 10.8218 20.7315 11.2646 20.7315ZM10.0248 4.48112C9.25431 4.48112 8.57241 3.80807 8.57241 3.0199C8.57241 2.23174 9.25431 1.56755 10.0248 1.56755C10.8218 1.56755 11.4771 2.23174 11.4771 3.0199C11.4771 3.80807 10.8218 4.48112 10.0248 4.48112ZM11.2557 25C18.234 25 22.5114 22.6886 22.5114 19.9611C22.5114 17.0475 17.8798 15.1701 14.2578 15.0372V16.7907C16.835 16.8881 20.2621 18.0836 20.2621 19.7751C20.2621 21.7765 16.4363 23.1492 11.2557 23.1492C6.06623 23.1492 2.24937 21.7853 2.24937 19.7751C2.24937 18.0836 5.66771 16.8881 8.25361 16.7907V15.0372C4.63158 15.1701 0 17.0475 0 19.9611C0 22.6886 4.27735 25 11.2557 25Z" fill="white"/>
                                    </svg>

                            </v-list-item-icon>
                            <v-list-item-content class="pt-1 pb-0">
                                <v-list-item-title v-text="`Track`" class="body-3 white--text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-block pr-0" :ripple="false" :to="{name: 'frontend.auth.register'}">
                            <v-list-item-icon class="d-block ma-0 text-center menurightlistheight">
                                <v-icon v-text="`mdi-account-circle`" class="white--text"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pt-1 pb-0">
                                <v-list-item-title v-text="`Sign up`" class="body-3 white--text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <div class="burger2 d-block d-lg-none">
                    <img src="@/assets/icons/svg/burger.svg" @click.stop="drawer = !drawer" alt="" width="40">
                </div>
                <div v-if="tracksearch" class="w-100 d-flex align-end search ml-10">
                    <v-text-field v-model="tracking" append-icon="mdi-magnify" color="white" placeholder="Track Shipping" hide-details flat @click:append="trackShipping" v-on:keyup.enter="trackShipping"></v-text-field>
                    <div class="d-flex w-100 h-100 justify-end"><v-icon class="white--text" :ripple="false" large @click="tracksearch = false">mdi-close</v-icon></div>
                </div>
            </div>
        </v-app-bar>
        <v-dialog
            v-model="drawer"
            fullscreen
            fixed
            temporary
            width="100%"
            content-class="primary"
            :transition="false"
            style="z-index:9999999"
        >
            <v-container class="h-100 py-0">
                <div class="d-flex d-lg-none flex-column justify-space-between h-100">
                    <div class="d-flex justify-space-between align-center d-lg-none mt-2">
                        <router-link :to="{name: 'frontend.home'}" style="width:40px;">
                            <img src="@/assets/logo/logo-mini-white-rounded.svg" class="py-2 d-sm-none" alt="Logo">
                            <img src="@/assets/logo/newwhite.svg" class="py-2 d-none d-sm-block" width="147px" alt="Logo">
                        </router-link>
                        <div class="text-18-60 white--text d-sm-none">
                            ES
                        </div>
                        <div class="d-flex">
                            <div class="d-none d-sm-block mr-4">
                                <v-list class="transparent d-flex" flat>
                                    <v-list-item-group v-model="item" class="d-flex flex-row align-self-center">
                                        <v-list-item>
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title v-text="`ES`" class="text-16-60 white--text"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <div class="">
                                            <v-list-item class="d-block">
                                                <v-list-item-icon class="d-block ma-0 text-center menurightlistheight">
                                                        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.2646 20.7315C11.6985 20.7315 12.2918 19.0578 12.2918 16.2682V8.40423C14.1516 7.94374 15.5065 6.26113 15.5065 4.26858C15.5065 1.91293 13.6202 0 11.2646 0C8.90009 0 7.0138 1.91293 7.0138 4.26858C7.0138 6.25228 8.36874 7.93487 10.2196 8.40423V16.2682C10.2196 19.0489 10.8218 20.7315 11.2646 20.7315ZM10.0248 4.48112C9.25431 4.48112 8.57241 3.80807 8.57241 3.0199C8.57241 2.23174 9.25431 1.56755 10.0248 1.56755C10.8218 1.56755 11.4771 2.23174 11.4771 3.0199C11.4771 3.80807 10.8218 4.48112 10.0248 4.48112ZM11.2557 25C18.234 25 22.5114 22.6886 22.5114 19.9611C22.5114 17.0475 17.8798 15.1701 14.2578 15.0372V16.7907C16.835 16.8881 20.2621 18.0836 20.2621 19.7751C20.2621 21.7765 16.4363 23.1492 11.2557 23.1492C6.06623 23.1492 2.24937 21.7853 2.24937 19.7751C2.24937 18.0836 5.66771 16.8881 8.25361 16.7907V15.0372C4.63158 15.1701 0 17.0475 0 19.9611C0 22.6886 4.27735 25 11.2557 25Z" fill="white"/>
                                                        </svg>

                                                </v-list-item-icon>
                                                <v-list-item-content class="pt-0 pb-0">
                                                    <v-list-item-title v-text="`Track`" class="body-3 white--text"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </div>
                                        <v-list-item class="d-block pr-0">
                                            <v-list-item-icon class="d-block ma-0 text-center menurightlistheight">
                                                <v-icon v-text="`mdi-account-circle`" class="white--text"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content class="pt-0 pb-0">
                                                <v-list-item-title v-text="`Sign up`" class="body-3 white--text"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </div>
                            <img src="@/assets/icons/svg/burger-close2.svg" @click.stop="changedrawer" class="py-2" alt="">
                        </div>
                    </div>
                    <div class="text-center">
                        <div class="text-18-60 white--text my-3">
                            Services
                        </div>
                        <div class="text-center">
                            <router-link class="d-block white--text my-2 text-16" :to="{name: 'frontend.services.expedited'}">Expedited shipping</router-link>
                            <router-link class="d-block white--text my-2 text-16" :to="{name: 'frontend.services.ltl'}">LTL Shipping</router-link>
                            <router-link class="d-block white--text my-2 text-16" :to="{name: 'frontend.services.international'}">International shipping</router-link>
                            <router-link class="d-block white--text my-2 text-16" :to="{name: 'frontend.services.airfreight'}">Airfreight</router-link>
                        </div>
                        <router-link class="d-block text-18-60 white--text my-3" :to="{name: 'frontend.careers'}">Careers</router-link>
                        <router-link class="d-block text-18-60 white--text my-3" :to="{name: 'frontend.agent'}">Agents</router-link>
                        <router-link class="d-block text-18-60 white--text my-3" :to="{name: 'frontend.about'}">About us</router-link>
                        <router-link class="d-block text-18-60 white--text my-3" :to="{name: 'frontend.blog'}">Blog</router-link>
                        <router-link class="d-block text-18-60 white--text my-3" :to="{name: 'frontend.contact'}">Contact us</router-link>
                    </div>
                    <div class="d-block d-sm-flex justify-space-between align-center">
                        <div class="d-flex justify-center justify-sm-start warwidth mb-4">
                            <div class="delivery">
                                <div class="text text-uppercase white--text body-3 text-center mb-2">
                                    Deliver To:
                                </div>
                                <div class="icons d-flex mt-1 justify-center">
                                    <img src="@/assets/icons/flag/usa.svg" class="mr-1" alt="USA" width="25">
                                    <img src="@/assets/icons/flag/canada.svg" class="mx-1" alt="USA" width="25">
                                    <img src="@/assets/icons/flag/mexico.svg" class="mx-1" alt="USA" width="25">
                                </div>
                            </div>
                        </div>
                        <v-btn x-large class="secondary py-1 px-10 text-14 text-transform-none mb-4 clbtmm2" depressed :to="{name: 'frontend.calculator'}">Get quotes</v-btn>
                        <div class="d-none d-sm-block d-lg-none warwidth">
                            <div class="d-flex d-lg-none justify-end">
                                <a href="#" class="mr-3">
                                    <img src="@/assets/icons/iconsnew/fbh.svg" alt="">
                                </a>
                                <a href="#" class="mr-3">
                                    <img src="@/assets/icons/iconsnew/instah.svg" alt="">
                                </a>
                                <a href="#">
                                    <img src="@/assets/icons/iconsnew/tweeh.svg" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <v-row class="h-100 d-none d-lg-flex">
                    <v-col cols="12" lg="3" class="drawer-list-border h-100">
                        <drawer-list @setitem="setitem" @closedrawer="changedrawer"/>
                    </v-col>
                    <v-col cols="12" lg="9" class="pl-0">
                        <div class="h-100 d-flex flex-column">
                            <drawer-track-menu class="ml-8"/>
                            <div class="mt-4">
                                <v-divider class=""></v-divider>
                            </div>
                            <component v-if="item" :is="`drawer-` + item" class="ml-8"></component>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-dialog>
    </div>
</template>

<script>
import DrawerList from './drawer/list.vue'
import DrawerTrackMenu from './drawer/trackmenu.vue'
import DrawerServices from './drawer/services.vue'
import DrawerContact from './drawer/contact.vue'
import DrawerBlog from './drawer/blog.vue'
import DrawerCareers from './drawer/careers.vue'
import DrawerAbout from './drawer/about.vue'
import DrawerAgents from './drawer/agents.vue'

export default {
    name: 'FrontendMenu',
    components: {
        DrawerList,
        DrawerTrackMenu,
        DrawerServices,
        DrawerContact,
        DrawerBlog,
        DrawerCareers,
        DrawerAbout,
        DrawerAgents
    },
    props: ['xswhite'],
    data: () => ({
        item: 'services',
        drawer: false,
        tracksearch: false,
        tracking: '',
    }),
    methods: {
        setitem(value){
            this.item = value
        },
        changedrawer(){
            this.drawer = !this.drawer;
        },
        trackShipping(){
            this.$store.dispatch('track/setTrack', this.tracking)
            this.$router.push({name: 'frontend.track'})
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/_variables.scss';
    .menurightlistheight{
        height: 26px;
        i{
            font-size: 25px;
        }
    }
    .v-toolbar__content{
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .mainmenu{
        .v-list-item{
            a{
                color:$white;
            }
            .v-list-item__title{
                color:$white;
            }
            a.router-link-exact-active.router-link-active{
                color: $blue_input;
                .v-list-item__title{
                    color:$blue_input;
                }
            }
            .v-list-item__title[aria-expanded="true"]{
                color:$blue_input;
            }
        }
        .burger{
            // position: absolute;
            // margin-left: -70px;
            img{
                border-radius: 100%;
                background-color: rgb(237, 242, 249, 0.2);
            }
        }
        .burger2{
            img{
                border-radius: 100%;
                background-color: rgb(237, 242, 249, 0.2);
            }
        }
        .v-list-item--link::before{
            content: none;
        }
        .search{
            border-bottom: rgba(145, 185, 251, 0.4) solid 1px;
            margin-bottom: 15px;
            .v-input{
                input{
                    margin-bottom: 10px;
                    color: #fff!important;
                    font-size: 28px;
                }
                input::placeholder{
                    color: rgba(255,255,255,0.6);
                    font-size: 28px;
                }
                .v-input__slot::after, .v-input__slot::before{
                    content: none;
                }
                .v-input__append-inner{
                    .v-icon::before{
                        color: rgba(255,255,255,0.6);
                    }
                }
            }
            .v-icon:after{
                background: transparent;
                color: rgba(255,255,255,0.6)!important;
            }
        }
    }
    .v-dialog__content{
        .v-dialog{
            overflow-x: hidden;
        }
        .burger{
            position: absolute;
            margin-left: -70px;

        }
        .drawer-list-border{
            border-right: 1px solid #91B9FB;
        }
        .v-divider{
            opacity: 0.4;
            border-top: 1px solid #91B9FB!important;
            border-color: #91B9FB!important;
            position: absolute;
            width: 100%;
        }
    }
    .v-menu__content{
        .v-list{
            padding: 10px 25px 10px 30px;
            .v-list-item{
                min-height: 24px;
                .v-list-item__title{
                    color: $dark;
                    i{
                        display: none;
                    }
                }
                .v-list-item__title::after{
                    content: "\F0142";
                    font: normal normal normal 12px/1 "Material Design Icons";
                    opacity: 0;
                }
            }
            .v-list-item:hover{
                .v-list-item__title{
                    color: $blue_first;
                    overflow: initial;
                }
                .v-list-item__title::after{
                    opacity: 1;
                }
            }
        }
    }
    .v-menu__content.frontendmenu{
        margin-top: 25px;
        margin-left: -75px;
    }
    @media all and (min-width: 960px) and (max-width: 1264px){
        .warwidth{
            width: 40%;
        }
    }
    @media all and (min-width:600px) and (max-width: 960px){
        .v-btn.w-100{
            width: auto;
        }
        .warwidth{
            width: 40%;
        }
    }
    @media all and (max-width:600px){
        .warwidth{
            width: 100%;
        }
        .clbtmm2{
            width: 100%;
        }
    }

</style>
