<template>
    <div class="d-flex flex-column justify-space-between ml-8 flex-grow-1 flex-shrink-0 blogdrawer">
        <div class="mt-16">
            <h1 class="heading-2 white--text">Careers</h1>
            <v-btn x-large class="drawerblue px-11 py-8 mt-11 text-transform-none white--text text-14-45" :ripple="false" depressed :to="{name: 'frontend.careers'}">All vacancies <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
        <footer-contact />
    </div>
</template>

<script>
import FooterContact from '@/components/base/drawer/footercontact.vue'

export default {
    name: 'DrawerCareers',
    components: {
        FooterContact,
    },

}
</script>

<style lang="scss">

</style>
