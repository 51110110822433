<template>
    <div class="d-flex flex-column justify-space-between ml-8 flex-grow-1 flex-shrink-0 aboutdrawer">
        <div class="mt-16">
            <img src="@/assets/icons/drawer/drawer-tanker.svg" alt="" style="position: absolute;right: 0;">
            <h1 class="heading-2 white--text mb-5">About us</h1>
            <p class="white--text w-60">Freight Crew is a full cycle, quality transportation company specialized in making time-limited deliveries. We are a team of forward-thinking shipment professionals, who will develop the individual transport solutions for your business. Our clients can focus on their company’s goals while we ensure the highest level of transportation service and find the best freight solutions for their business.</p>
            <v-btn x-large class="drawerblue px-11 py-8 mt-11 text-transform-none white--text text-14-45" :ripple="false" depressed :to="{name: 'frontend.about'}">More information <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
        <footer-contact />
    </div>
</template>

<script>
import FooterContact from '@/components/base/drawer/footercontact.vue'

export default {
    name: 'DrawerAbout',
    components: {
        FooterContact,
    },

}
</script>

<style lang="scss">

</style>
