<template>
    <base-counter :bgimg="bgimg" :items="items">
        <slot/>
    </base-counter>
</template>

<script>
import BaseCounter from '@/components/base/BaseCounter.vue'

export default {
    name: 'Counter',
    props: ['bgimg'],
    components: {
        BaseCounter
    },
    data: () => ({
        items: [
            {
                quantity: 50,
                unit: 'k',
                text: 'Over 50 k of loyal <br/> clients trust us',
            },
            {
                quantity: 11,
                unit: 'b',
                text: 'We shipped over 10 700 746 344 <br/> pounds of various freights',
            },
            {
                quantity: 10,
                unit: 'm',
                text: 'We delivered over <br/> 10 millions of shipments',
            },
        ]
    })
}
</script>
