<template>
    <div class="d-flex flex-column justify-space-between ml-8 flex-grow-1 flex-shrink-0">
        <div class="">
            <v-row>
                <v-col cols="12" lg="5" v-for="item in service_data">
                    <service-card class="bg-transparent" customclass="emptyclass" :hidefooter="true" :data="item" :iconsize="item.is"></service-card>
                </v-col>
            </v-row>
        </div>
        <footer-contact />
    </div>
</template>

<script>
import ServiceCard from '@/components/base/ServiceCard.vue'
import FooterContact from '@/components/base/drawer/footercontact.vue'

export default {
    name: 'DrawerServices',
    components: {
        ServiceCard,
        FooterContact
    },
    data: () => ({
        service_data: [
            {
                img: 'svg/shipping/expedited.svg',
                title: 'Expedited shipping',
                text: 'This transportation service gives your cargo a special shipment priority over others, making the delivery process faster',
                type: 'white',
                to: 'frontend.services.expedited',
                is: 50
            },
            {
                img: 'svg/shipping/ltl.svg',
                title: 'LTL Shipping',
                text: 'Less-than-truckload shipping or less than load (LTL) is the shipping of relatively small freight',
                type: 'white',
                to: 'frontend.services.ltl',
                is: 50
            },
            {
                img: 'svg/shipping/international.svg',
                title: 'International shipping',
                text: 'Cost-effective international freight shipping, at the moment operating in the United States, Canada, and Mexico',
                type: 'white',
                to: 'frontend.services.international',
                is: 40
            },
            {
                img: 'svg/shipping/airplane.svg',
                title: 'Airfreight',
                text: 'Airfreight shipping services are fast and efficient that makes it so valuable to companies',
                type: 'white',
                to: 'frontend.services.airfreight',
                is: 50
            }
        ]
    })
}
</script>

<style lang="scss">
</style>
