<template>
    <div class="d-flex flex-column justify-space-between ml-8 flex-grow-1 flex-shrink-0 agentsdrawer">
        <div class="mt-16">
            <img src="@/assets/icons/drawer/drawer-truck.svg" alt="" style="position: absolute;right: 0;">
            <h1 class="heading-2 white--text mb-5">Join our team <br/>and become an agent</h1>
            <p class="white--text">If you are a skillful salesperson Freight Crew is the <br/>partner to help you grow and increase your income.</p>
            <v-btn x-large class="drawerblue px-11 py-8 mt-11 text-transform-none white--text text-14-45" :ripple="false" depressed :to="{name: 'frontend.agent'}">More information <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
        <footer-contact />
    </div>
</template>

<script>
import FooterContact from '@/components/base/drawer/footercontact.vue'

export default {
    name: 'DrawerAgents',
    components: {
        FooterContact,
    },

}
</script>

<style lang="scss">

</style>
