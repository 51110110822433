<template>
    <div class="main">
        <div class="primary header p-relative">
            <v-container :fluid="$vuetify.breakpoint.lgAndUp" class="px-lg-16">
                <frontend-menu :xswhite="xswhite"/>
            </v-container>
            <v-container fluid class="pa-0 ">
                <transition name="fade" mode="out-in" appear>
                    <slot name="header"/>
                </transition>
            </v-container>
        </div>
        <div class="">
            <v-container fluid class="pa-0 index p-relative">
                <slot name="main"/>
            </v-container>
        </div>
        <div class="footer second-silver">
            <v-container class="py-0">
                <footer-block />
            </v-container>
        </div>
        <div class="fixedbottommenu d-md-none">
            <div class="d-flex justify-space-between mx-4 my-2">
                <div class="" v-for="(item, i) in mdAndDownMenu()">
                    <router-link class="routerlink" :to="{ name: item.to , params: {} }">
                        <div class="text-10-45 text-center">
                            <img class="inactive" :src="getSmMenuIcon(item.icon)" alt="" height="20px">
                            <img class="active" :src="getSmMenuIcon(item.icon + '-active')" alt="" height="20px">
                            <div class="text-center menutext" v-html="item.text"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import FrontendMenu from '@/components/base/FrontendMenu.vue'
import FooterBlock from '@/components/base/FooterBlock.vue'

export default {
    name: 'BlueTopLayout',
    components: {
        FrontendMenu,
        FooterBlock
    },
    props: ['xswhite'],
    data: () => ({
        items: [
            {
                to: 'frontend.home',
                text: 'Home',
                icon: 'home'
            },
            {
                to: 'frontend.calculator',
                text: 'Get quotes',
                icon: 'quotes'
            },
            {
                to: 'frontend.track',
                text: 'Track',
                icon: 'track'
            },
            {
                to: 'frontend.auth',
                text: 'Account',
                icon: 'account'
            },
        ]
    }),
    mounted () {
        window.scrollTo(0, 0)
    },
    methods: {
        mdAndDownMenu(){
            return this.items.filter( function(item){
                if(item.icon) return item;
            })
        },
        getSmMenuIcon(icon, active = false){
            if(active)
                return require('@/assets/icons/svg/xs-account/'+ icon +'-active.svg');
            return require('@/assets/icons/svg/xs-account/'+ icon +'.svg');
        }
    }
}
</script>
<style lang="scss">
@media all and (max-width:600px){
    .xs-bg-light{
        .header.primary{
            background: #fff!important;
            border-color: #fff!important;
            .burger2 img{
                background: #CDE0FF;
            }
        }
    }
}
body{
    background: #fff;
}
.fixedbottommenu{
    position: fixed;
    width: 100%;
    background: white;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    .menutext{
        color: rgba(31,31,31, 0.5)
    }
    .routerlink{
        .inactive{
            display: initial;
        }
        .active{
            display: none;
        }
    }
    .routerlink.router-link-exact-active{
        color: rgba(84,150,240,1);
        .inactive{
            display: none;
        }
        .active{
            display: initial;
        }
        .menutext{
            color: rgba(84,150,240,1);
        }
    }
}
</style>
