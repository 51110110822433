<template>
    <v-row class="contact">
        <v-col cols="12" lg="4">
            <h1 v-if="!nohead" class="heading-2 white--text mt-6 mt-md-0 mb-md-5">Contact Us</h1>
            <p v-if="subtitle" v-html="subtitle" class="white--text mb-4 mb-md-10"></p>
            <v-list color="transparent" disabled>
                <v-list-item-group class="d--block d-sm-flex d-lg-block">
<!--                    <v-list-item class="px-0">-->
<!--                        <v-list-item-icon class="my-0 my-md-4">-->
<!--                            <img src="@/assets/icons/contact/phone.svg" alt="">-->
<!--                        </v-list-item-icon>-->
<!--                        <v-list-item-content class="py-2">-->
<!--                            <v-list-item-subtitle class="text-16 white&#45;&#45;text">Phone number</v-list-item-subtitle>-->
<!--                            <v-list-item-title class="text-20-60 white&#45;&#45;text">-->
<!--                                <a href="tel: +1 469 830 8777" class="white&#45;&#45;text">+1 469 830 8777</a>-->
<!--                            </v-list-item-title>-->
<!--                        </v-list-item-content>-->
<!--                    </v-list-item>-->
                    <v-list-item class="my-2 px-0">
                        <v-list-item-icon class="my-0 my-md-4">
                            <img src="@/assets/icons/contact/email.svg" alt="">
                        </v-list-item-icon>
                        <v-list-item-content class="py-2">
                            <v-list-item-subtitle class="text-16 white--text">Email</v-list-item-subtitle>
                            <v-list-item-title class="text-20-60 white--text">
                                <a href="mailto: info@freightcrew.us" class="white--text">info@freightcrew.us</a>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="px-0">
                        <v-list-item-icon class="my-0 my-md-4">
                            <img src="@/assets/icons/contact/location.svg" alt="">
                        </v-list-item-icon>
                        <v-list-item-content class="py-2">
                            <v-list-item-subtitle class="text-16 white--text">Location</v-list-item-subtitle>
                            <v-list-item-title class="text-20-60 white--text">
                                USA, Texas
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-col>
        <v-col cols="12" lg="8">
            <v-card color="white pa-5" flat>
                <v-card-title class="text-32-60">
                    Request to Contact
                </v-card-title>
                <contact-form />
                    <!-- <v-form>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-text-field
                                    label="First Name"
                                    class="silver contactinput"
                                    outlined
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field
                                    label="Email address *"
                                    class="silver contactinput"
                                    outlined
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="12">
                                <v-textarea
                                    label="How can we help?"
                                    class="silver contactinput"
                                    rows="3"
                                    outlined
                                    hide-details
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-btn x-large class="secondary px-15 py-8 text-14 text-capitalize">Get help</v-btn>
                            <p class="mb-0 ml-8">By submitting the form you <br/> agree with <span class="text-decoration-underline"><a href="#">terms and conditions</a></span></p>
                        </div>
                    </v-form> -->
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ContactForm from '@/components/base/ContactForm.vue'
export default {
    name: 'ContactUs',
    props: ['subtitle', 'nohead'],
    components: {
        ContactForm
    }
}
</script>

<style lang="scss">
.contact{

}
</style>
