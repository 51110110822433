<template>
    <div class="d-flex flex-column justify-space-between ml-8 flex-grow-1 flex-shrink-0 contactdrawer">
        <div class="w-80 mt-16">
            <img src="@/assets/icons/drawer/drawer-pack.svg" alt="" style="position: absolute;right: 0;">
            <h1 class="heading-2 white--text">Contact Us</h1>
            <contact-form textclass="bg-blue-input contactinput white--text" />
        </div>
        <footer-contact class="mt-5"/>
    </div>
</template>

<script>
import FooterContact from '@/components/base/drawer/footercontact.vue'
import ContactForm from '@/components/base/ContactForm.vue'

export default {
    name: 'DrawerContact',
    components: {
        FooterContact,
        ContactForm
    },
}
</script>

<style lang="scss">
.contactdrawer{
    .v-label, p, a{
        color: #fff !important;
    }
    .v-input--is-focused{
        fieldset{
            border: 1px solid #fff;

        }
    }
    input{
        color: #fff !important;
        margin-left: 8px;
    }
    label{
        left: 8px !important;
    }
}
</style>
