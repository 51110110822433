<template>
    <div class="bg-second-blue counter py-md-16" :style="{'background-image': 'url(' + bgimg + ')'}">
        <v-container class="">
            <v-col lg="6" class="pl-0">
                <slot/>
            </v-col>
            <v-row>
                <v-col cols="12" sm="4" v-for="item in items">
                    <div class="">
                        <counter-interface :item="item" class="mb-6"/>
                    </div>
                    <p class="text-16 white--text" v-html="item.text"></p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CounterInterface from './CounterInterface.vue'
export default {
    name: 'BaseCounter',
    props: ['bgimg', 'items'],
    components: {
        CounterInterface
    }
}
</script>

<style lang="scss">
.counter.bg-second-blue{
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    .heading-extra{
        letter-spacing: -0.03em;
    }
    .heading-extra::before{
        content: "";
        border: 1px solid;
        margin: 3px 20px 0 0;
        display: inline-block;
        height: 44px;
    }
}
@media all and (max-width: 600px){
    .counter.bg-second-blue{
        background-image: none!important;
    }
}
</style>
