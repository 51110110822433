<template>
    <div class="">
        <layout>
            <template v-slot:header>
                <div class="py-6">
                    <div class="mainpage-mainblock mt-md-6 mt-lg-16 w-100 index">
                        <v-container :fluid="$vuetify.breakpoint.lgAndUp" class="px-lg-16">
                            <h1 class="heading-1 white--text">Worldwide <br/> freight shipping </h1>
                            <div class="text-20-60 white--text mt-12">Get instant quotes</div>
                            <v-btn v-if="!showexpansion && $vuetify.breakpoint.xs" x-large class="secondary px-10 py-4 mt-5 text-transform-none" @click="showexpansion = !showexpansion" depressed>Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                            <v-row v-if="showexpansion || !$vuetify.breakpoint.xs" class="mt-5">
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Pickup city or postal code</label>
                                    <v-autocomplete
                                        v-model="form.from.geoModel"
                                        :items="form.from.geoItems"
                                        :search-input.sync="form.from.geoSearch"
                                        background-color="#EDF2F9"
                                        outlined
                                        class="mt-2"
                                        hide-no-data
                                        hide-selected
                                        item-text="label"
                                        placeholder="Pickup city"
                                        hide-details
                                        return-object
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Requested loading date</label>
                                    <v-menu
                                        v-model="form.from.datepicker"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        class="index3"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                ref="calendarevent"
                                                v-model="computedDateFormatted"
                                                class="mt-2"
                                                outlined
                                                background-color="#EDF2F9"
                                                placeholder="08/05/2020"
                                                append-icon="mdi-calendar-month"
                                                hide-details
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details
                                                @click:append="opencalendar"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.from.date"
                                            no-title
                                            @input="form.from.datepicker = false"
                                        ></v-date-picker>
                                    </v-menu>

                                </v-col>
                            </v-row>
                            <v-btn  v-if="showexpansion || !$vuetify.breakpoint.xs" x-large class="secondary px-15 py-8 mt-5 text-transform-none" depressed @click="assign_and_redirect">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-container>
                    </div>
                    <v-row class="mb-md-16 mb-lg-0 pb-md-6 pb-lg-0">
                        <v-col cols="7" sm="11" md="7" offset-sm="1" offset-md="5" class="mainpage-mainimage py-0">
                            <img src="@/assets/icons/svg/world.svg" alt="" width="100%" class="worldmap d-none d-lg-block">
                            <img src="@/assets/icons/svg/world-sm.svg" alt="" width="100%" class="worldmap worldmapxs d-block d-lg-none">
                            <img src="@/assets/icons/svg/maintrucksm.svg" alt="" class="d-sm-none trucksm index2">
                            <img src="@/assets/icons/svg/maintrucksm2.svg" alt="" class="d-none d-sm-block d-lg-none trucksm index2">
                            <img src="@/assets/icons/svg/tanker.svg" alt="" width="28%" class="element tanker tanker-animated d-none d-lg-block">
                            <img src="@/assets/icons/svg/truck.svg" alt="" width="11%" class="element truck truck-animated d-none d-lg-block">
                            <img src="@/assets/icons/svg/points.svg" alt="" width="40%" class="element points points-animated d-none d-lg-block">
                            <img src="@/assets/icons/svg/aircraft.svg" alt="" width="17%" class="element aircraft aircraft-animated d-none d-lg-block">
                        </v-col>
                    </v-row>
                    <div class="mainpage-footerblock w-100 d-none d-sm-block pt-md-11 pt-lg-0">
                        <v-container>
                            <v-list class="transparent mx-auto" flat>
                                <v-list-item-group class="d-flex flex-row align-self-center h-100">
                                    <v-list-item class="pl-0" :ripple="false">
                                        <v-list-item-content>
                                            <router-link :to="{ name: 'frontend.services.expedited', params: {} }">
                                                <v-list-item-title class="text-20-60 d-block d-md-flex align-center">
                                                    <img src="@/assets/icons/svg/shipping/expedited.svg" alt="Expedited shipping Icon" class="mr-5">
                                                    <span class="border white--text d-block d-md-auto text-16-60">Expedited shipping</span>
                                                </v-list-item-title>
                                            </router-link>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <router-link :to="{ name: 'frontend.services.ltl', params: {} }">
                                                <v-list-item-title class="text-center text-20-60 d-block d-md-flex align-center justify-center">
                                                    <img src="@/assets/icons/svg/shipping/ltl.svg" alt="LTL shipping Icon" class="mr-5">
                                                    <span class="border white--text  d-block d-md-auto text-16-60">LTL shipping</span>
                                                </v-list-item-title>
                                            </router-link>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <router-link :to="{ name: 'frontend.services.international', params: {} }">
                                                <v-list-item-title class="text-center text-20-60 d-block d-md-flex align-center justify-center">
                                                    <img src="@/assets/icons/svg/shipping/international.svg" alt="International shipping Icon" class="mr-5" style="margin-bottom: 10px;">
                                                    <span class="border white--text d-block d-md-auto text-16-60">International shipping</span>
                                                </v-list-item-title>
                                            </router-link>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pr-0" :ripple="false">
                                        <v-list-item-content>
                                            <router-link :to="{ name: 'frontend.services.airfreight', params: {} }">
                                                <v-list-item-title class="text-center text-20-60 d-block d-md-flex align-center justify-center">
                                                    <img src="@/assets/icons/svg/shipping/airplane.svg" alt="Airfreight Icon" class="mr-5">
                                                    <span class="border white--text d-block d-md-auto text-16-60">Airfreight</span>
                                                </v-list-item-title>
                                            </router-link>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-container>
                    </div>
                </div>
            </template>
            <template v-slot:main>
                <v-container fluid class="index p-relative" :class="{'white': $vuetify.breakpoint.mdAndUp}">
                    <v-container class="py-16" >
                        <how-work :item="how_work"/>
                    </v-container>
                </v-container>
                <aircraft-city/>
                <div class="py-2 py-sm-16 xs-white">
                    <v-container class="my-6 services">
                        <h1 class="heading-2 mb-md-10">Services</h1>
                        <v-row class="d-flex align-stretch">
                            <v-col cols="12" sm="6" lg="3">
                                <v-card class="silver h-100 d-flex align-start flex-column" flat>
                                    <v-card-text class="mb-auto">
                                        <div class="d-flex d-sm-block">
                                            <div class="img mb-sm-5">
                                                <img class="unhover" src="@/assets/icons/iconsnew/car-blue.svg" width="50" alt="">
                                                <img class="hover" src="@/assets/icons/iconsnew/car-white.svg" height="50" alt="">
                                            </div>
                                            <router-link :to="{ name: 'frontend.services.expedited', params: {} }">
                                                <h4 class="text-20-60 text-decoration-underline">Expedited shipping</h4>
                                            </router-link>
                                        </div>
                                        <p class="text-16 mt-5">This transportation service gives your cargo a special shipment priority over others, making the delivery process faster</p>
                                    </v-card-text>
                                    <v-card-actions class="d-none d-sm-block mt-5">
                                        <router-link :to="{ name: 'frontend.services.expedited', params: {} }">
                                            <div class="service-button">
                                                <v-icon class="icon">mdi-chevron-right</v-icon>
                                                <div class="service-button-long text-center text-14-45 dark-text">
                                                    More information <v-icon class="dark-text">mdi-chevron-right</v-icon>
                                                </div>
                                            </div>
                                        </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-card class="silver h-100 d-flex align-start flex-column" flat>
                                    <v-card-text class="mb-auto">
                                        <div class="d-flex d-sm-block">
                                            <div class="img mb-sm-5">
                                                <img class="unhover" src="@/assets/icons/iconsnew/truck-blue.svg" height="50" alt="">
                                                <img class="hover" src="@/assets/icons/iconsnew/truck-white.svg" width="50" alt="">
                                            </div>
                                            <router-link :to="{ name: 'frontend.services.ltl', params: {} }">
                                                <h4 class="text-20-60 text-decoration-underline">LTL Shipping</h4>
                                            </router-link>
                                        </div>
                                        <p class="text-16 mt-5">Less-than-truckload shipping or less than load (LTL) is the shipping of relatively small freight</p>
                                    </v-card-text>
                                    <v-card-actions class="d-none d-sm-block mt-5">
                                        <router-link :to="{ name: 'frontend.services.ltl', params: {} }">
                                            <div class="service-button">
                                                <v-icon class="icon">mdi-chevron-right</v-icon>
                                                <div class="service-button-long text-center text-14-45 dark-text">
                                                    More information <v-icon class="dark-text">mdi-chevron-right</v-icon>
                                                </div>
                                            </div>
                                        </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-card class="silver h-100 d-flex align-start flex-column" flat>
                                    <v-card-text class="mb-auto">
                                        <div class="d-flex d-sm-block">
                                            <div class="img mb-sm-5" style="height:50px;">
                                                <img class="unhover" src="@/assets/icons/iconsnew/international-blue.svg" height="40" alt="">
                                                <img class="hover" src="@/assets/icons/iconsnew/international-white.svg" height="40" alt="">
                                            </div>
                                            <router-link :to="{ name: 'frontend.services.international', params: {} }">
                                                <h4 class="text-20-60 text-decoration-underline">International shipping</h4>
                                            </router-link>
                                        </div>
                                        <p class="text-16 my-5">Cost-effective international freight shipping operating in the United States, Canada, and Mexico</p>
                                    </v-card-text>
                                    <v-card-actions class="d-none d-sm-block mt-5">
                                        <router-link :to="{ name: 'frontend.services.international', params: {} }">
                                            <div class="service-button">
                                                <v-icon class="icon">mdi-chevron-right</v-icon>
                                                <div class="service-button-long text-center text-14-45 dark-text">
                                                    More information <v-icon class="dark-text">mdi-chevron-right</v-icon>
                                                </div>
                                            </div>
                                        </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-card class="silver h-100 d-flex align-start flex-column" flat>
                                    <v-card-text class="mb-auto">
                                        <div class="d-flex d-sm-block">
                                            <div class="img mb-sm-5">
                                                <img class="unhover" src="@/assets/icons/iconsnew/airplane-blue.svg" height="50" alt="">
                                                <img class="hover" src="@/assets/icons/iconsnew/airplane-white.svg" height="50" alt="">
                                            </div>
                                            <router-link :to="{ name: 'frontend.services.airfreight', params: {} }">
                                                <h4 class="text-20-60 text-decoration-underline">Airfreight</h4>
                                            </router-link>
                                        </div>
                                        <p class="text-16 mt-5">Airfreight shipping services are fast and efficient that makes it so valuable to companies</p>
                                    </v-card-text>
                                    <v-card-actions class="d-none d-sm-block mt-5">
                                        <router-link :to="{ name: 'frontend.services.airfreight', params: {} }">
                                            <div class="service-button">
                                                <v-icon class="icon">mdi-chevron-right</v-icon>
                                                <div class="service-button-long text-center text-14-45 dark-text">
                                                    More information <v-icon class="dark-text">mdi-chevron-right</v-icon>
                                                </div>
                                            </div>
                                        </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <div class="jot second-silver py-sm-16">
                    <v-container class="my-6 agent">
                        <join-our-team />
                    </v-container>
                </div>
                <counter :bgimg="require('@/assets/logo/bg-logo-blue.svg')">
                    <h1 class="heading-2 white--text mt-6 mt-md-0 mb-4 mb-md-10">About</h1>
                    <p class="font-16 white--text mb-10">Freight Crew is a full cycle, quality transportation company specialized in making time-limited deliveries in the United States, Canada, and Mexico. We are a team of forward-thinking shipment professionals, who will develop the individual transport solutions for your business.</p>
                </counter>
                <cooperation />
                <div class="contact primary py-md-16">
                    <v-container class="my-6">
                        <contact-us />
                    </v-container>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
// @ is an alias to /src
import Layout from '@/components/layouts/BlueTopLayout.vue'
import AircraftCity from '@/components/pages/AircraftCity.vue'
import Counter from '@/components/pages/Counter.vue'
import Cooperation from '@/components/pages/Cooperation.vue'
import JoinOurTeam from '@/components/pages/JoinOurTeam.vue'
import ContactUs from '@/components/pages/ContactUs.vue'
import HowWork from '@/components/pages/HowWork.vue'

import geodataMixin from '@/mixins/geodataMixin'

export default {
    name: 'Home',
    mixins: [geodataMixin],
    components: {
        Layout,
        AircraftCity,
        Counter,
        JoinOurTeam,
        Cooperation,
        ContactUs,
        HowWork,
    },
    data:() => ({
        showexpansion: false,
        how_work: {
            title: 'How does it work',
            subtitle: 'We know that organizing an efficient freight shipping in a limited time frame may challenge enough. So, we undertake this task from start to finish: planning the most suitable route, selecting the transportation mode, and careful freight handling (loading and unloading)',
            list: [
                {
                    icon: require('@/assets/icons/svg/hwork/msg.svg'),
                    text: 'Submit your request and enter the shipment details'
                },
                {
                    icon: require('@/assets/icons/svg/hwork/dir.svg'),
                    text: 'We examine your request and propose several effective solutions'
                },
                {
                    icon: require('@/assets/icons/svg/hwork/truck.svg'),
                    text: 'You choose the most suitable solution and we provide the carrier'
                },
                {
                    icon: require('@/assets/icons/svg/hwork/box.svg'),
                    text: 'Your freight is delivered'
                },
            ]
        }
    }),
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.form.from.date)
        },
    },
    watch: {
        'form.date' (val) {
            this.form.from.dateFormatted = this.formatDate(this.form.from.date)
        },
    },
    methods: {
        assign_and_redirect(){
            this.calculator_assign();
            this.calculator_redirect();
        },
        calculator_assign(){
            this.$store.dispatch('calculator/setForm', {
                form: this.form
            });
        },
        calculator_redirect(){
            this.$router.push({name: 'frontend.calculator'})
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        opencalendar(){
            this.$refs['calendarevent'].$refs['input'].click()
        }
    }
}
</script>
<style lang="scss">
    .primary.header{
        overflow: initial!important;;
    }
    .mainpage-mainblock{
        position:absolute;
        .v-input__slot::before{
            border: 0 !important;
        }
        .v-input__slot{
            fieldset{
                // color: #EDF2F9 !important;
                border: none;
            }
        }
        .v-btn.v-size--x-large{
            font-weight: 450;
            font-size: 14px;
            line-height: 22px;
            text-transform: none;

            i.v-icon{
                margin-top: 2px;
            }
        }
    }
    .mainpage-footerblock{
        // position: absolute;
        .border{
            padding-bottom: 3px;
            border-bottom: 1px solid #EDF2F9;
        }
    }
    .mainpage-mainimage{
        max-height: 750px;
        .element{
            position: absolute;
        }
        .tanker{
            // right: 4%;
            right: 11%;
            top: 5%;
        }
        .truck{
            right: 26%;
            top: 40%;
            z-index: 2
        }
        .aircraft{
            right: 36%;
            z-index: 2;
            top: 15%;
        }
        .points{
            right: 9%;
            top: 20%;
            z-index: 1;
        }
        .tanker-animated {
        	-webkit-animation: tanker 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        	        animation: tanker 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
        .truck-animated {
        	-webkit-animation: truck 1.0s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        	        animation: truck 1.0s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
        .aircraft-animated {
        	-webkit-animation: aircraft 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        	        animation: aircraft 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
    }
    .services{
        .unhover{
            display: block;
        }
        @media all and (max-width: 600px){
            .img img{
                width: 30px;
                margin-right: 10px;
            }
        }
        .v-card{
            transition: 0.2s;
            padding: 35px 30px;
            // height: 326px;
            .v-card__text{
                padding: 0;
            }
            .v-card__actions{
                padding: 0;
                // position: absolute;
                // bottom: 0;
            }
            h4, p{
                color: #1f1f1f;
            }
            .hover{
                display: none;
            }
            .service-button{
                border: 1px solid #1f1f1f;
                border-radius: 50px;
                padding: 12px;
                transition: 0.2s;
                .v-icon{
                    color: #1f1f1f;
                }
                .service-button-long{
                    display: none;
                }
            }
        }
    }
    @media all and (min-width:600px) and (max-width: 1273px){
        .contact.primary{
            height: 610px;
            margin-bottom: 140px;
        }
    }
    @media all and (max-width: 960px){
        .jot.second-silver{
            .container{
                padding-left: 0;
                padding-right: 0;
                .intro{
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }

    }
    @media all and (min-width:600px) and (max-width: 960px){
        .mainpage-mainimage{
            margin-top: -200px;
        }
        .trucksm{
            position: absolute;
            right: 0;
            top: 130px;
        }
        .text-20-60{
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
        .border{
            padding-bottom: 3px;
            border-bottom: 0!important;
        }
    }
    @media all and (min-width:960px){
        .v-card.silver:hover{
            background-color: #5496F0 !important;
            .unhover{
                display: none;
            }
            .hover{
                display: block;
            }
            h4, p{
                color: #ffffff;
            }
            .service-button{
                border: 1px solid #ffffff;
                border-radius: 50px;
                padding: 12px;
                .v-icon{
                    color: #ffffff;
                }
                .service-button-long{
                    display: none;
                }
            }
            .service-button:hover{
                border: none;
                background: #FFFFFF;
                border-radius: 25px;
                padding: 12px 28px;
                .v-icon.icon{
                    display: none;
                }
                .service-button-long{
                    display: block;
                }
            }
        }
    }
    @media all and (min-width:960px) and (max-width: 1264px){
        .mainpage-mainimage{
            margin-top: -200px;
        }
        .trucksm{
            position: absolute;
            right: 0;
            top: 130px;
        }
    }
    @media all and (max-width: 600px){
        .worldmap{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
        .trucksm{
            position: absolute;
            right: 0;
            bottom: -130px;
            z-index: 20;
        }
        .mainpage-mainblock{
            position:relative;
        }
        .worldmapxs{
            height: 100%;
        }
    }

    @-webkit-keyframes tanker {
      0% {
        -webkit-transform: translateY(0) translateX(0);
                transform: translateY(0) translateX(0);
      }
      100% {
          -webkit-transform: translateY(20%) translateX(23%);
                  transform: translateY(20%) translateX(23%);
      }
    }
    @keyframes tanker {
      0% {
        -webkit-transform: translateY(0) translateX(0);
                transform: translateY(0) translateX(0);
      }
      100% {
        -webkit-transform: translateY(20%) translateX(23%);
                transform: translateY(20%) translateX(23%);
      }
    }
    @-webkit-keyframes truck {
        0% {
            -webkit-transform: translateY(-70px) translateX(-100px);
            transform: translateY(-70px) translateX(-100px);
        }
        100% {
            -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
        }
    }
    @keyframes truck {
        0% {
            -webkit-transform: translateY(-70px) translateX(-100px);
            transform: translateY(-70px) translateX(-100px);
        }
        100% {
            -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
        }
    }
    @-webkit-keyframes aircraft {
        0% {
            -webkit-transform: translateY(70px) translateX(-100px);
            transform: translateY(70px) translateX(-100px);
        }
        100% {
            -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
        }
    }
    @keyframes aircraft {
        0% {
            -webkit-transform: translateY(70px) translateX(-100px);
            transform: translateY(70px) translateX(-100px);
        }
        100% {
            -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
        }
    }

</style>
