<template>
    <div class="drawer-menu d-flex flex-column justify-space-between h-100">
        <div class="">
            <div class="burger">
                <img src="@/assets/icons/svg/burger-close.svg" @click.stop="$emit('closedrawer')" class="py-2" alt="">
            </div>
            <router-link :to="{name: 'frontend.home'}"><img src="@/assets/logo/newwhite.svg" class="py-2 ml-1" alt="Logo" width="147px"></router-link>
            <v-list flat class="mt-16 bg-transparent">
                <v-list-item-group v-model="current" class="pt-3">
                    <v-list-item v-for="item in menu" :key="item.template" :ripple="false" class="pl-0">
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name" class="text-20-60" @click="$emit('setitem', item.template)"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
        <div class="mb-16">
            <div class="text-20-60 white--text">
                Deliver to:
            </div>
            <div class="icons d-flex mt-1">
                <img src="@/assets/icons/flag/usa.svg" class="mr-1" alt="USA" width="40">
                <img src="@/assets/icons/flag/canada.svg" class="mx-1" alt="USA" width="40">
                <img src="@/assets/icons/flag/mexico.svg" class="mx-1" alt="USA" width="40">
            </div>
            <v-btn class="secondary px-15 py-7 mt-5 text-14-60 text-transform-none" :to="{name: 'frontend.calculator'}" depressed>Get quotes</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DrawerList',
    data: () => ({
        menu: [
            {
                template: 'services',
                name: 'Services',
            },
            {
                template: 'agents',
                name: 'Agents',
            },
            {
                template: 'about',
                name: 'About us',
            },
            {
                template: 'careers',
                name: 'Careers',
            },
            {
                template: 'blog',
                name: 'Blog',
            },
            {
                template: 'contact',
                name: 'Contact us',
            }
        ],
        current: 0,
    }),
    methods: {
    }
}
</script>

<style lang="scss">
    .drawer-menu{
        .v-list-item{
            .v-list-item__title{
                color: #CDE0FF;
                opacity: 0.6;
            }
        }
        .v-list-item.v-item--active{
            .v-list-item__title{
                opacity: 1;
                color: #FFFFFF;
            }
        }
        .v-list-item--active::before,
        .v-list-item--active::after{
            content: " ";
            width: 10px;
            height: 10px;
            min-height: auto;
            position: absolute;
            border-bottom: 1px solid #91B9FB;
            border-left: 1px solid #91B9FB;
            right: -17px;
            transform: rotate(45deg);
            background-color: #4c8efc;
        }
    }
</style>
