<template>
    <v-row class="my-md-10 howwork">
        <v-col cols="12" md="5" class="pt-10">
            <h1 class="heading-2" v-html="item.title"></h1>
            <p class="text-16 my-10 d-none d-sm-block" v-html="item.subtitle"></p>
            <v-btn x-large class="secondary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex" depressed :to="{name: 'frontend.calculator'}">Get quotes</v-btn>
        </v-col>
        <v-col cols="12" md="6" offset-md="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
            <v-list class="py-0 bg-transparent">
                <v-list-item-group>
                    <v-list-item v-for="element in item.list" class="d-flex align-center iconafterdash py-2 pl-0 pl-sm-auto px-0 pr-sm-auto"  :ripple="false">
                        <v-list-item-icon class="align-self-center my-0 order-1 order-sm-0">
                            <img src="@/assets/icons/svg/blue-point.svg" class="" alt="">
                        </v-list-item-icon>
                        <v-list-item-content class="order-0 order-sm-1">
                            <v-list-item-title class="white-space-initial">
                                <div class="d-flex align-center">
                                    <div v-if="element.icon" class="">
                                        <img :src="element.icon" alt="" class="ml-0 mr-5 mx-sm-10 listiconimg">
                                    </div>
                                    <div class="black--text text-20-45 white-space-initial" v-html="element.text"></div>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'HowWork',
    props: ['item']
}
</script>

<style lang="scss">
.howwork{
    .listiconimg{
        height: 55px;
    }
    .v-list-item__icon{
        background-color:#fff;
    }
    .iconafterdash:not(:last-child)::before{
        background-color: transparent;
        border-left: 1px dashed #91B9FB;
        content: " ";
        height: 90%;
        position: absolute;
        opacity: 1;
        right: auto;
        left: 11px;
        top: 60%;
    }
    .iconafterdash:hover::before{
        opacity: 1 !important;
    }
    .v-list-item--link::before{
        background-color: transparent;
    }
    @media all and (max-width: 600px){
        .listiconimg{
            height: 30px;
        }
        .iconafterdash:not(:last-child)::before{
            right: 11px;
            left: auto;
            top: 60%;
        }
        .v-list-item__icon:first-child{
            margin-right: 0;
        }
    }
}
</style>
