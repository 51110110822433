<template>
    <v-dialog
      v-model="local_dialog"
      content-class="agentformdialog index3 p-relative"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
        <v-app-bar class="d-block d-md-none" dense color="#3D475B">
            <div class="w-100 text-right">
                <v-icon class="white--text mr-5" @click="local_dialog = !local_dialog">mdi-window-close</v-icon>
            </div>
        </v-app-bar>
        <div class="agentform index3 p-relative">
            <v-card class="px-10 py-7" style="overflow:auto">
                <div class="d-flex justify-space-between">
                    <h3 v-if="e1 <= 2" class="text-28-60 mb-4">Application form</h3>
                    <img class="d-none d-md-block" src="@/assets/icons/svg/burger-blue-close.svg" @click="local_dialog = !local_dialog" alt="" style="cursor:pointer">
                </div>
                <v-stepper v-model="e1" class="box-shadow-none">
                    <div v-if="e1 <= 2" class="stepper-header d-flex">
                        <div class="flex-grow-1 flex-shrink-0">
                            <div class="text-14-60 text-uppercase mb-3">Contact information</div>
                            <div class="d-flex align-center">
                                <img src="@/assets/icons/svg/blue-point.svg" alt="">
                                <div class="line w-100"></div>
                            </div>
                        </div>
                        <div class="flex-grow-1 flex-shrink-0">
                            <div class="text-14-60 text-uppercase mb-3">Experience</div>
                            <div v-if="e1 <= 1" class="d-flex align-center">
                                <img src="@/assets/icons/svg/blue-point-inactive.svg" alt="">
                                <div class="dot-line w-100"></div>
                            </div>
                            <div v-if="e1 > 1" class="d-flex align-center">
                                <img src="@/assets/icons/svg/blue-point.svg" alt="">
                                <div class="line w-100"></div>
                            </div>
                        </div>
                    </div>
                    <v-stepper-items>
                        <v-stepper-content step="1" class="px-0">
                            <v-form lazy-validation ref="form">
                                <v-card flat>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">First Name*</label>
                                                <v-text-field
                                                    v-model="form.first_name"
                                                    placeholder="First name"
                                                    background-color="silver"
                                                    outlined
                                                    flat
                                                    hide-details="auto"
                                                    :rules="requiredRules"
                                                    required
                                                ></v-text-field>
                                            </div>
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Phone*</label>
                                                <v-text-field
                                                    v-model="form.phone"
                                                    placeholder="Phone"
                                                    background-color="silver"
                                                    outlined
                                                    flat
                                                    hide-details="auto"
                                                    :rules="phoneRules"
                                                    required
                                                ></v-text-field>
                                            </div>
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Location*</label>
                                                <v-text-field
                                                    v-model="form.location"
                                                    placeholder="Location"
                                                    background-color="silver"
                                                    outlined
                                                    flat
                                                    hide-details="auto"
                                                    :rules="requiredRules"
                                                    required
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Last Name*</label>
                                                <v-text-field
                                                    v-model="form.last_name"
                                                    placeholder="Last name"
                                                    background-color="silver"
                                                    outlined
                                                    flat
                                                    hide-details="auto"
                                                    :rules="requiredRules"
                                                    required
                                                ></v-text-field>
                                            </div>
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Email*</label>
                                                <v-text-field
                                                    v-model="form.email"
                                                    placeholder="Email"
                                                    background-color="silver"
                                                    outlined
                                                    flat
                                                    hide-details="auto"
                                                    :rules="emailRules"
                                                    required
                                                ></v-text-field>
                                            </div>
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">How did you learn about Freight Crew?</label>
                                                <v-text-field v-model="form.learn_about" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none d-flex mb-5" depressed @click="move12">Next step <v-icon>mdi-chevron-right</v-icon></v-btn>
                            </v-form>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="px-0">
                            <v-form lazy-validation ref="form2">
                                <v-card flat>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">What is your current position?*</label>
                                                <v-select
                                                    v-model="form.position"
                                                    :items="positions"
                                                    background-color="silver"
                                                    label="Choose position"
                                                    outlined
                                                    flat
                                                    solo
                                                    hide-details="auto"
                                                    :rules="requiredRules"
                                                    required
                                                ></v-select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Experience in freight brokerage</label>
                                                <v-text-field v-model="form.experience" placeholder="Number of years" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Loads per month</label>
                                                <v-text-field v-model="form.month_load" placeholder="Average" background-color="silver" outlined flat hide-details="auto"></v-text-field>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Profit Margin %*</label>
                                                <v-text-field
                                                    v-model="form.profit_margin"
                                                    placeholder="Average"
                                                    background-color="silver"
                                                    outlined
                                                    flat
                                                    hide-details="auto"
                                                    :rules="requiredRules"
                                                    required
                                                ></v-text-field>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Gross profit per month</label>
                                                <v-text-field v-model="form.month_gross_profit"  background-color="silver" outlined flat hide-details="auto"></v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" lg="12">
                                            <div class="mb-4">
                                                <label for="" class="text-14-45">Additional comments/Why are you interested in this opportunity?*</label>
                                                <textarea
                                                    v-model="form.comments"
                                                    rows="4"
                                                    class="w-100 silver rounded-5"
                                                    :rules="requiredRules"
                                                    required
                                                ></textarea>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-btn x-large class="previous px-12 py-8 text-14 text-transform-none mr-4 mb-5" @click="e1 = 1" depressed><v-icon>mdi-chevron-left</v-icon> Previous</v-btn>
                                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none mb-5" @click="submit" depressed>Submit</v-btn>
                            </v-form>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="px-0">
                            <v-card flat>
                                <div class="d-flex flex-column align-center my-6">
                                    <img src="@/assets/icons/svg/message.svg" class="my-5" alt="">
                                    <h2 class="text-28-60">Thank you for applying!</h2>
                                    <p class="text-14-45 my-5 w-50 text-center">We will review your application and will get back to you as quickly as possible</p>
                                    <v-btn x-large class="secondary px-12 py-8 my-5 text-14 text-transform-none" @click="resetForm" depressed>Continue</v-btn>
                                </div>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import ValidationMixin from '@/mixins/validationMixin.js';
export default {
    name: 'AgentForm',
    // props: ['dialog'],
    data: () => ({
        local_dialog: false,
        e1: 1,
        form: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            location: '',
            learn_about: '',
            position: '',
            experience: '',
            month_load: '',
            profit_margin: '',
            month_gross_profit: '',
            comments: '',
        },
        positions:[
            'Agent (1099)', 'Employee (W-2)', 'Owner (Self Employed)'
        ]
    }),
    mixins: [
        ValidationMixin
    ],
    methods: {
        changeDialog(){
            this.local_dialog = !this.local_dialog;
        },
        resetForm(){
            var that = this;
            this.local_dialog = false;
            setTimeout(() => this.e1 = 1, 1000)
        },
        move12(){
            if(this.$refs.form.validate()){
                this.e1 = 2;
                window.scrollTo(0,0);
            }
        },
        submit(){
            if(this.$refs.form2.validate()){
                this.axios.post(process.env.VUE_APP_API + 'mailer/v1/agent', this.form).then(response => {
                    if(response.data == 1)
                        this.e1 = 3;
                })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.agentformdialog{
    width: 650px;
}
@media all and (max-width: 1273px){
    .agentformdialog{
        width: 100%;
        height: 100%;
        margin: 0;
        .agentform{
            height: 100%;
            .v-card{
                height: 100%;
            }
        }
    }
}
.agentform{
    .v-card{
        .v-input, textarea{
            margin-top: 7px;
        }
        .v-text-field{
            fieldset{
                border: none;
            }
            input::placeholder{
                font-size: 14px !important;
                color: $silver-dark !important;
            }
        }
    }
    .stepper-header{
        .line{
            border-bottom: 2px solid #5496F0;
        }
        .dot-line{
            border-bottom: 2px dashed #CDE0FF;
        }
    }
    .v-stepper__content{
        .previous{
            background-color: #ECECEC;
            color: $silver-dark;
        }
    }
    .text-28-60{
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
    }
}
</style>
