<template>
    <div class="d-flex justify-space-between mb-16">
        <div class="contact d-flex align-center">
            <img src="@/assets/icons/contact/phone.svg" alt="">
            <div class=" ml-5">
<!--                <div class="text-16 white&#45;&#45;text">-->
<!--                    Phone number-->
<!--                </div>-->
<!--                <div class="text-20-60 white&#45;&#45;text">-->
<!--                    <a href="tel: +1 469 830 8777" class="white&#45;&#45;text">+1 469 830 8777</a>-->
<!--                </div>-->
            </div>
        </div>
        <div class="contact d-flex align-center">
            <img src="@/assets/icons/contact/email.svg" alt="">
            <div class=" ml-5">
                <div class="text-16 white--text">
                    Email
                </div>
                <div class="text-20-60 white--text">
                    <a href="mailto: info@freightcrew.us" class="white--text">info@freightcrew.us</a>
                </div>
            </div>
        </div>
        <div class="contact d-flex align-center">
            <img src="@/assets/icons/contact/location.svg" alt="">
            <div class=" ml-5">
                <div class="text-16 white--text">
                    Location
                </div>
                <div class="text-20-60 white--text">
                    USA, Texas
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DrawerFooterContact',
}
</script>

<style lang="scss">
</style>
