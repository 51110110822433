<template>
    <div class="cooperation py-16 second-silver">
        <v-container class="my-6">
            <h1 class="heading-2 mb-md-10">Cooperation</h1>
            <v-row class="list">
                <v-col v-for="item in items" cols="12" sm="6" md="4">
                    <v-card color="silver" flat>
                        <v-card-text class="d-flex align-stretch flex-column justify-space-around">
                            <div class="topblock text-center">
                                <img class="unhover" :src="require('@/assets/icons/cooperation/' + item.logo)" alt="">
                                <img class="hover" :src="require('@/assets/icons/cooperation/' + item.logoh)" alt="">
                            </div>
                            <div class="bottomblock">
                                <div class="text-center text-20-60 mb-3 bottom-title">
                                    {{item.name}}
                                </div>
                                <div class="text-center text-16">
                                    {{item.desc}}
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Cooperation',
    data: () => ({
        items: [
            {
                logo: 'coyote.svg',
                logoh: 'coyote-white.svg',
                name: 'Coyote Logistics',
                desc: 'Third-party logistics provider',
                color: 'silver',
            },
            {
                logo: 'robinson.svg',
                logoh: 'robinson-white.svg',
                name: 'C.H. Robinson',
                desc: 'Logistic company',
                color: 'bg-blue',
            },
            {
                logo: 'xpo.svg',
                logoh: 'xpo-white.svg',
                name: 'XPO Logistics, Inc.',
                desc: 'Logistic company',
                color: 'silver',
            },
            {
                logo: 'transplace.svg',
                logoh: 'transplace-white.svg',
                name: 'Transplace',
                desc: 'Logistic company',
                color: 'silver',
            },
            {
                logo: 'arc.svg',
                logoh: 'arc-white.svg',
                name: 'ArcBest',
                desc: 'Logistic company',
                color: 'silver',
            },
            {
                logo: 'ups.svg',
                logoh: 'ups-white.svg',
                name: 'The UPS Store',
                desc: 'Franchisor of service centers',
                color: 'silver',
            },
        ]
    })
}
</script>

<style lang="scss">
.cooperation{
    .list{
        .v-card{
            transition: 0.2s;
            .v-card__text{
                height: 270px;
                .topblock{
                    height: 100px;
                    display: flex;
                    img{
                        margin: auto;
                    }
                }
                .bottomblock{
                    .bottom-title::before{
                        content: "";
                        border-top: 2px solid #91B9FB;
                        display: block;
                        width: 44px;
                        margin: 0 auto 20px auto;
                    }
                }
            }
            .hover{
                display: none;
            }
        }
        @media all and (min-width:960px){
            .v-card:hover{
                background-color: #5496F0 !important;
                .unhover{
                    display: none;
                }
                .hover{
                    display: block;
                }
                .text-center{
                    color: #ffffff;
                }
                .bottomblock{
                    div{
                        color: white;
                    }
                    .bottom-title::before{
                        border-top: 2px solid #FFFFFF !important;
                    }
                }
            }
        }
    }
}
</style>
