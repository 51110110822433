<template>
    <div class="trackmenu">
        <div class="d-flex flex-row align-center justify-space-between">
            <v-text-field v-model="tracking" placeholder="Track shipping" append-icon="mdi-magnify" solo flat background-color="bg-transparent pl-0" color="customcolor" hide-details @click:append="trackShipping" v-on:keyup.enter="trackShipping"></v-text-field>
            <v-list class="transparent d-flex" flat>
                <v-list-item-group v-model="item" class="d-flex flex-row align-self-center">
                    <v-list-item>
                        <v-list-item-content class="py-0">
                            <v-list-item-title v-text="`ES`" class="text-16-60 white--text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <div class="">
                        <v-list-item class="d-block">
                            <v-list-item-icon class="d-block ma-0 text-center menurightlistheight">
                                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.2646 20.7315C11.6985 20.7315 12.2918 19.0578 12.2918 16.2682V8.40423C14.1516 7.94374 15.5065 6.26113 15.5065 4.26858C15.5065 1.91293 13.6202 0 11.2646 0C8.90009 0 7.0138 1.91293 7.0138 4.26858C7.0138 6.25228 8.36874 7.93487 10.2196 8.40423V16.2682C10.2196 19.0489 10.8218 20.7315 11.2646 20.7315ZM10.0248 4.48112C9.25431 4.48112 8.57241 3.80807 8.57241 3.0199C8.57241 2.23174 9.25431 1.56755 10.0248 1.56755C10.8218 1.56755 11.4771 2.23174 11.4771 3.0199C11.4771 3.80807 10.8218 4.48112 10.0248 4.48112ZM11.2557 25C18.234 25 22.5114 22.6886 22.5114 19.9611C22.5114 17.0475 17.8798 15.1701 14.2578 15.0372V16.7907C16.835 16.8881 20.2621 18.0836 20.2621 19.7751C20.2621 21.7765 16.4363 23.1492 11.2557 23.1492C6.06623 23.1492 2.24937 21.7853 2.24937 19.7751C2.24937 18.0836 5.66771 16.8881 8.25361 16.7907V15.0372C4.63158 15.1701 0 17.0475 0 19.9611C0 22.6886 4.27735 25 11.2557 25Z" fill="white"/>
                                    </svg>

                            </v-list-item-icon>
                            <v-list-item-content class="pt-0 pb-0">
                                <v-list-item-title v-text="`Track`" class="body-3 white--text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <v-list-item class="d-block pr-0">
                        <v-list-item-icon class="d-block ma-0 text-center menurightlistheight">
                            <v-icon v-text="`mdi-account-circle`" class="white--text"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="pt-0 pb-0">
                            <v-list-item-title v-text="`Sign up`" class="body-3 white--text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DrawerTrackMenu',
    data:() => ({
        item: 0,
        tracking: null,
    }),
    methods:{
        trackShipping(){
            this.$store.dispatch('track/setTrack', this.tracking)
            this.$router.push({name: 'frontend.track'})
        }
    }
}
</script>

<style lang="scss">
.trackmenu{
    .v-input__slot{
        input{
            color: #ffffff;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 28px;
        }
        input::placeholder{
            color: #ffffff;
            opacity: 0.6;
        }
        .v-input__append-inner{
            .v-icon:before{
                color: #ffffff;
                opacity: 0.6;
            }
            .v-icon:before.customcolor--text{
                color: #ffffff;
                opacity: 1;
            }
        }
        .v-text-field__slot{
            width: 260px;
        }
    }
    .v-input__control {
        display: flex;
        flex-direction: column;
        height: auto;
        min-width: 0;
        flex-wrap: nowrap;
        width: auto;
        flex-grow: 0;
    }
}
</style>
