<template>
    <div class="">
        <v-form lazy-validation ref="form" v-if="!snackbar">
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                    v-model="form.name"
                    label="First Name"
                    :class="text_class"
                    :rules="requiredRules"
                    required
                    outlined
                    hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                    v-model="form.mail"
                    label="Email address *"
                    :class="text_class"
                    :rules="emailRules"
                    required
                    outlined
                    hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" lg="12">
                    <v-textarea
                    v-model="form.text"
                    label="How can we help?"
                    :class="text_class"
                    :rules="requiredRules"
                    required
                    rows="3"
                    outlined
                    hide-details
                    ></v-textarea>
                </v-col>
            </v-row>
            <div class="d-sm-flex align-center mt-5" v-if="!snackbar">
                <v-btn x-large class="secondary px-15 py-8 text-14 text-transform-none" @click="submit()" depressed>Get help</v-btn>
                <p class="mb-0 ml-sm-8 mt-4 mt-sm-auto">By submitting the form you <br/> agree with <span class="text-decoration-underline dark-text"><router-link :to="{ name: 'frontend.terms', params: {} }">terms and conditions</router-link></span></p>
            </div>
        </v-form>
        <v-card flat v-if="snackbar">
            <div class="d-flex flex-column align-center my-6">
                <img src="@/assets/icons/svg/message.svg" class="my-5" alt="">
                <h2 class="text-28-60">Thank you for message!</h2>
                <p class="text-14-45 my-5 w-50 text-center">We will review your request and will get back to you as quickly as possible</p>
                <v-btn x-large class="secondary px-12 py-8 my-5 text-14 text-transform-none" @click="snackbar = false" depressed>Continue</v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
import ValidationMixin from '@/mixins/validationMixin.js';
export default {
    name: 'ContactForm',
    props: ['textclass'],
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        text_class: 'silver contactinput',
        snackbar: false,
        form: {
            name: '',
            mail: '',
            text: '',
        }
    }),
    mounted() {
        if(this.textclass)
            this.text_class = this.textclass;
    },
    methods: {
        submit(){
            if(this.$refs.form.validate()){
                this.axios.post(process.env.VUE_APP_API + 'mailer/v1/contact', this.form).then(response => {
                    this.$refs.form.reset();
                    this.snackbar = true;
                })
            }
        }
    }
}
</script>

<style lang="scss">
.contactinput fieldset{
    border: 0;
}
.contactinput:hover fieldset{
    border: 0;
}
.contact{
    .v-text-field__slot{
        margin-left: 8px !important;
    }
}
</style>
