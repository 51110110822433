<template>
    <v-card :class="`silver h-100 d-flex align-start flex-column ` + defaultclass" flat>
        <v-card-text class="mb-auto">
            <div class="img mb-5">
                <v-hover v-slot="{ hover }">
                    <div class="">
                        <img v-if="data.img" v-show="!hover || !data.img_hover" class="" :src="require('@/assets/icons/' + data.img)" :height="iconheight" alt="">
                        <img v-if="data.img_hover" v-show="hover" class="hover" :src="require('@/assets/icons/' + data.img_hover)" :height="iconheight" alt="">
                    </div>
                </v-hover>
                <!-- @/assets/icons/svg/shipping/expedited-blue.svg -->
            </div>
            <h4 v-if="hidefooter" class="text-20-60 text-decoration-underline" :class="data.type + `--text`"><router-link :to="{ name: data.to, params: {} }" :class="data.type + `--text`">{{data.title}}</router-link></h4>
            <h4 v-if="!hidefooter" :class="`text-20-60 text-decoration-underline ` + data.type + `--text`" v-html="data.title"></h4>
            <p :class="`text-16 mt-5 ` + data.type + `--text`" v-html="data.text"></p>
        </v-card-text>
        <v-card-actions v-if="!hidefooter" class="">
            <router-link :to="{ name: data.to, params: {} }" :class="data.type + `--text`">
                <div class="service-button">
                    <v-icon class="icon">mdi-chevron-right</v-icon>
                    <div class="service-button-long text-center text-14-45 dark-text">
                        More information <v-icon class="dark-text">mdi-chevron-right</v-icon>
                    </div>
                </div>
            </router-link>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'ServiceCard',
    props: ['data', 'hidefooter', 'customclass', 'iconsize'],
    data: () => ({
        defaultclass: 'servicecard',
        iconheight: 31,
    }),
    mounted(){
        if(this.customclass)
            this.defaultclass = this.customclass
        if(this.iconsize)
            this.iconheight = this.iconsize
    }
}
</script>

<style lang="scss">
.servicecard{
    padding: 35px 30px;
    // height: 326px;
    .v-card__text{
        padding: 0;
    }
    .v-card__actions{
        padding: 0;
        // position: absolute;
        // bottom: 0;
    }
    .hover{
        display: none;
    }
    .service-button{
        border: 1px solid #1f1f1f;
        border-radius: 50px;
        padding: 12px;
        transition: 0.2s;
        .v-icon{
            color: #1f1f1f;
        }
        .service-button-long{
            display: none;
        }
    }
}
.servicecard.silver:hover{
    background-color: #5496F0 !important;
    .unhover{
        display: none;
    }
    .hover{
        display: block;
    }
    h4, p{
        color: #ffffff;
    }
    .service-button{
        border: 1px solid #ffffff;
        border-radius: 25px;
        padding: 12px;
        .v-icon{
            color: #ffffff;
        }
        .service-button-long{
            display: none;
        }
    }
    .service-button:hover{
        border: none;
        background: #FFF;
        border-radius: 5px;
        padding: 12px 28px;
        .v-icon.icon{
            display: none;
        }
        .service-button-long{
            display: block;
        }
    }
}

</style>
